<template>
  <v-progress-linear height="5" :color="barColor" :background-color="color" :value="barValue" />
</template>

<script>
export default {
  name: "PainelCardBar",
  props: ['color','total','value','minValue'],
  computed: {
    barColor() {
      return this.$utils.colors.pSBC(0.23, this.color);
    },
    barValue() {
      return Math.max(this.minValue,(this.value * 100) / this.total);
    }
  }
}
</script>

<style scoped>

</style>